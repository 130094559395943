const initialState = {
  loading: false,
  prorroga_id: null,
  prorrogas: [],
  item: {
    prorroga_cliente: {
      cuentas_pagar: 0,
      reten_liberadas: 0,
    },
  },
  giro: false,
  comentarios_automaticos: [],
  form_options: {
    payment_options: [],
    docu_cuota_options: [],
    pagare_options: [],
    feriados: [],
    ruta_has: [],
    ruta_documentos: [],
    cuentas: [],
  },
};

const prorroga = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_PRORROGA':
      return { ...state, loading: action.data };
    case 'SET_PRORROGAS':
      return { ...state, prorrogas: action.data };
    case 'SET_PRORROGA':
      return { ...state, item: { ...state.item, ...action.data } };
    case 'SET_OPTIONS_PRORROGA':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_GIRO_PRORROGA':
      return { ...state, giro: action.data };
    case 'ADD_COMENTARIO_PRORROGA':
      return { ...state, comentarios_automaticos: [...state.comentarios_automaticos, action.data] };
    default:
      return { ...state };
  }
};
export default prorroga;
