const initialState = {
  rut: '',
  open_modal: false,
  amount: 0,
  comprobante: '',
  tipo_pasivo: '',
  pasivos: [],
};

const pasivos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CAJA_PASIVOS':
      return { ...state, ...action.data };
    default:
      return { ...state };
  }
};
export default pasivos;
