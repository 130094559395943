const initialState = {
  filter_options: {
    sucursales: [],
    ejecutivos: [],
    vias: [],
    tipo_pagos: [],
    tipos_cuentas_logros_cob: [],
    ruta_hash: '',
    ruta_documentos: '',
  },
  modal_prorrogas: false,
  modal_moras: false,
  modal_documentos_y_pagos: false,
  modal_documentos: false,
  modal_amortizaciones: false,
  modal_protestos_detalle: false,
  modal_doc_x_cobrar_detalle: false,
  modal_cta_especiales_detalle: false,
  cajadia: null,
  profordia: null,
  cuentas_especiales: null,
  codlinea: null,
  is_documento: false,
  causas_judiciales: [],
  garantias: [],
};

const consultaConsolidada = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_CONSOLIDADA':
      return {
        ...state,
        filter_options: {
          sucursales: [],
          ejecutivos: [],
          vias: [],
          tipo_pagos: [],
          tipos_cuentas_logros_cob: [],
        },
        modal_prorrogas: false,
        modal_moras: false,
        modal_documentos_y_pagos: false,
        modal_documentos: false,
        modal_amortizaciones: false,
        modal_protestos_detalle: false,
        modal_doc_x_cobrar_detalle: false,
        modal_cta_especiales_detalle: false,
        cajadia: null,
        profordia: null,
        cuentas_especiales: null,
        codlinea: null,
        is_documento: false,
      };
    case 'SET_OPTIONS_CONSOLIDADO':
      return { ...state, filter_options: { ...state.filter_options, ...action.data } };
    case 'SET_MODALS_CONSOLIDADA':
      return { ...state, ...action.data };
    case 'SET_CODLINEA_CONSOLIDADA':
      return { ...state, codlinea: action.data };
    case 'SET_IS_DOCUMENTO_CONSOLIDADA':
      return { ...state, is_documento: action.data };
    case 'SET_CAUSAS_JUDICIALES_CONSOLIDADA':
      return { ...state, causas_judiciales: action.data };
    case 'SET_GARANTIAS_CONSOLIDADA':
      return { ...state, garantias: action.data };
    default:
      return { ...state };
  }
};
export default consultaConsolidada;
